import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container, Item } from 'semantic-ui-react';
import parse from 'html-react-parser';

import Layout from '../components/layout';

const News = ({
  data: {
    wpCategory: {
      category,
      posts: { nodes },
    },
  },
}) => (
  <Layout>
    <Container className="wordpress">
      <h1 style={{ marginTop: '3rem' }}>{category}</h1>
      <Item.Group style={{ marginTop: '2rem' }}>
        {nodes.map(post => (
          <Item key={post.slug}>
            {post.featuredImage && (
              <Item.Image
                size="small"
                src={post.featuredImage.node.localFile.childImageSharp.fixed.src}
                style={{ marginTop: '0.5em' }}
              />
            )}
            <Item.Content>
              <Item.Header as="h2" style={{ marginTop: 0 }}>
                <a href={`/${category.toLowerCase()}/${post.slug}`}>{post.title}</a>
              </Item.Header>
              <Item.Meta>{post.date}</Item.Meta>
              <Item.Description>{parse(post.excerpt)}</Item.Description>
            </Item.Content>
          </Item>
        ))}
      </Item.Group>
    </Container>
  </Layout>
);

News.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default News;

export const query = graphql`
  query categoryPage($category: String) {
    wpCategory(name: { eq: $category }) {
      category: name
      posts {
        nodes {
          title
          date(formatString: "DD MMMM YYYY")
          slug
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
